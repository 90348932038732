import UIHandler from './ui';
import Utils from "./util";

window.onload = () => {
  const ui = new UIHandler();
  // Check if you can read token from the URI
  // if yes, set token and send user back to home page.
  const URItoken = Utils.getToken();
  if (URItoken){
    Utils.setCookie(URItoken);
    // send user back to the home page, so they can book mark the page without token.
    window.location.href = process.env.COGNITO_CALLBACK_URL;
    return;
  }
  // URI contains no token, check cookie for the existing access_token value.
  // if its not set, or expired, send user to authorize again.
  const accessToken = Utils.getCookie("access_token");
  if (accessToken) {
    ui.updateUsersList('Active');
  } else {
    // access token cookie is not set, start authentication
    Utils.authorize();
  }
}

